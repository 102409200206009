@import url(//fonts.googleapis.com/css?family=Inconsolata:400,700|Montserrat:700|Roboto+Mono);

html {
  font: 16px/1.5 Inconsolata, sans-serif;
}

body {
  margin: 2rem 0 5rem;
  color: #222;
}

a {
  color: red; /* From http://clrs.cc */
  text-decoration: none;
}

a:hover, a:focus {
  text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-family: Montserrat, sans-serif;
  margin: 0 0 0.5rem -0.1rem /* align left edge */;
  line-height: 1;
  color: #111;
  text-rendering: optimizeLegibility;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

h1 a {
  color: inherit;
  text-align: center;
}

h2 {
  margin-top: 2rem;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
  color: #3A1C71;
  line-height: 1.5em;
}

@media (min-width: 30rem) {
  h2 {
    margin-top: 1.5rem;
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}

h3 {
  margin-top: 2rem;
  font-size: 1rem;
  margin-bottom: 0rem;
  line-height: 1.5rem;
  color: #3A1C71;
}

h4, h5, h6 {
  margin-top: 1.5rem;
  font-size: 1rem;
  text-transform: uppercase;
}

p, ul, ol, dl, table, pre, blockquote {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul, ol {
  padding-left: 1.5rem;
  list-style-type: square;
}

dd {
  margin-left: 1.5rem;
}

blockquote {
  margin-left: 0;
  margin-right: 0;
  padding: .5rem 1rem;
  border-left: .25rem solid #ccc;
  color: #666;
}

blockquote p:last-child {
  margin-bottom: 0;
}

hr {
  border: none;
  margin: 1.5rem 0;
  border-bottom: 1px solid #ccc;
  position: relative;
  top: -1px;
}

.container img, .container iframe {
  max-width: 100%;
}

.container img {
  margin: 0 auto;
  display: block;
}

table {
  width: 100%;
  border: 1px solid #ccc;
  border-collapse: collapse;
}

td, th {
  padding: .25rem .5rem;
  border: 1px solid #ccc;
}

pre, code {
  font-family: inherit;
  background-color: #eee;
}

pre {
  padding: .5rem 1rem;
  font-size: 0.8rem;
}

code {
  padding: .1rem .25rem;
}

pre > code {
  padding: 0;
}

.testimonial {
  font-family: 'Roboto Mono', sans-serif;
  color: #4353ff;
  font-size: 1.25em;
  line-height: 1.75em;
  font-weight: 500;
  margin: 2em 0;
}

.colorize {
  font-size: 3.5em;
  margin: 1em 0;
  line-height: 1;
}

.questions {
  font-size: 1.25em;
}